var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"tippy",rawName:"v-tippy"}],staticClass:"markings-list-item",class:{
    disabled: _vm.disabled,
  },attrs:{"content":(_vm.itemUnit?.name || '?').toUpperCase() || ''},on:{"click":function($event){$event.stopPropagation();return _vm.handleClick.apply(null, arguments)}}},[_c('div',{staticClass:"markings-list-item-flex"},[_c('div',[_c('MarkingStatusBadge',{attrs:{"status":_vm.item.labelStatus}}),_c('div',{staticClass:"number"},[_vm._v(" "+_vm._s(_vm.item.serialNumber)+" ")])],1),(_vm.upperLevelUnit && !_vm.isCompatibilityView && !_vm.hideActions)?_c('div',{directives:[{name:"tippy",rawName:"v-tippy"}],staticClass:"parent-number",class:{
        'no-parent': !_vm.itemParent.serialNumber,
      },attrs:{"content":(_vm.upperLevelUnit?.name || '?').toUpperCase() || ''},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.handleParentClick.apply(null, arguments)}}},[(_vm.loadingParents)?_c('i',{staticClass:"fas fa-loader fa-spin"}):_c('span',[(_vm.itemParent.serialNumber)?_c('i',{staticClass:"fas fa-arrow-up-wide-short"}):_vm._e(),_vm._v(" "+_vm._s(_vm.itemParent.serialNumber || _vm.$t('markings.noConnectionToParentElement'))+" ")])]):_vm._e(),(_vm.lowerLevelUnit && !_vm.isCompatibilityView)?_c('div',{directives:[{name:"tippy",rawName:"v-tippy"}],staticClass:"children-numbers",attrs:{"content":(_vm.lowerLevelUnit?.name || '?').toUpperCase()},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.handleClickOnChildren.apply(null, arguments)}}},[_c('i',{staticClass:"fas fa-arrow-down-wide-short"}),_vm._v(" "+_vm._s(_vm.itemChildren.length)+" ")]):_vm._e(),_c('div',{staticClass:"date"},[_c('i',{staticClass:"far fa-calendar"}),_vm._v(" "+_vm._s(_vm.formatDate(_vm.item.created))+" ")]),_vm._m(0),_c('div',{staticClass:"line"},[_vm._v(" "+_vm._s(_vm.lineName)+" "),_c('span',{staticClass:"subline-name"},[_vm._v(" / "+_vm._s(_vm.labelingPointName)+" ")])]),(!_vm.hideActions)?_c('div',{staticClass:"item-actions is-placeholder"},[_c('div',{staticClass:"item-action",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();}}},[_c('i',{staticClass:"fas fa-unlink"})]),_c('div',{staticClass:"item-action",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();}}},[_c('i',{staticClass:"fas fa-trash"})])]):_vm._e()]),(_vm.isCompatibilityView)?_c('div',{staticClass:"compatibility-row"},[_c('div',{staticClass:"compatibility-parent-box"},[_c('i',{staticClass:"fas fa-arrow-up-wide-short"}),_c('div',{directives:[{name:"tippy",rawName:"v-tippy"}],key:_vm.itemParent.id,staticClass:"number",class:{
          'the-same-parent': _vm.isCompatibilityCorrect,
        },attrs:{"content":_vm.isCompatibilityCorrect
          ? `${_vm.$t('cockpit.details')} - ${(_vm.upperLevelUnit?.name || '').toUpperCase() || ''}`
          : (`${_vm.$t('markings.cannotAccessParentElementDetails')}
            (${(_vm.upperLevelUnit?.name || '').toUpperCase() || ''}), ` +
            `${_vm.$t('markings.becauseNotAllScannedElementsHaveTheSameParentNumber')}.`)},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();_vm.isCompatibilityCorrect ? _vm.handleOpenById(_vm.itemParent.id) : null}}},[(_vm.upperLevelUnit)?_c('span',{staticClass:"unit"},[_vm._v(" "+_vm._s(_vm.upperLevelUnit?.name.toUpperCase() || '')+": ")]):_vm._e(),_vm._v(" "+_vm._s(_vm.itemParent.serialNumber || _vm.$t('markings.noConnectionToParentElement'))+" ")])])]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"order-id"},[_c('span',{staticClass:"label"},[_vm._v(" Order ID: ")]),_vm._v(" ? ")])
}]

export { render, staticRenderFns }