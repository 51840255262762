<script>
export default {
  name: 'CockpitMatryoshkaItemOperations',
};
</script>

<template>
  <div class="operations-list">
    <div class="operation-item">
      ?? ?? ??
    </div>
    <div class="operation-item">
      ?? ?? ?? ??
    </div>
  </div>
</template>

<style scoped lang="scss">
.operations-list {
  display: flex;
  flex-direction: column;
  gap: 8px;

  .operation-item {
    border: 1px solid #F0F1F3;
    width: 100%;
    height: 42px;
    padding: 0 20px;
    font-size: 15px;
    font-weight: 500;
    display: flex;
    align-items: center;
    border-radius: 6px;
    cursor: pointer;

    &:hover {
      background-color: #F0F1F3;
    }
  }
}
</style>
