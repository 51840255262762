<template>
  <div>
    <div
      class="properties-type-header"
      @click="unfolded=!unfolded"
    >
      <div>
        {{ header }}
      </div>
      <div class="arrow">
        <i
          v-if="!unfolded"
          class="fa-solid fa-angle-down"
        />
        <i
          v-else
          class="fa-solid fa-angle-up"
        />
      </div>
    </div>

    <div v-if="unfolded">
      <div
        v-for="property in skuProperties"
        :key="property.id"
        class="label-value-row"
      >
        <div class="label">
          <div>
            {{ property.name }}
          </div>
          <div class="label-description">
            {{ property.description }}
          </div>
        </div>
        <div class="value">
          <div class="value-value">
            <div
              v-if="editForm"
              v-tippy="{
                arrow: true,
                placement: 'bottom',
                trigger: !property.editable ? 'mouseenter' : ''
              }"
              :content="$t('sku.toEditTurnOffSync')"
            >
              <BFormSelect
                v-if="property.valueType === propertyValueTypes.dictionary"
                v-model="property.value"
                :disabled="!property.editable"
                :options="getDictionaryEntriesForBox(property.dictionaryId)"
                value-field="value"
                text-field="text"
                @change="propertyUpdated(property.id)"
              />
              <BFormCheckbox
                v-else-if="property.valueType === propertyValueTypes.boolean"
                v-model="property.value"
                :disabled="!property.editable"
                switch
                size="md"
                @change="propertyUpdated(property.id)"
              />
              <BFormInput
                v-else
                v-model="property.value"
                :disabled="!property.editable"
                :state="isValueValid(property.valueType, property.value) ? null : false"
                @input="propertyUpdated(property.id)"
              />
              <Error
                v-if="!isValueValid(property.valueType, property.value)"
                class="mt-1"
                :message="`${$t('error.valueIsInNotExpectedType')} ${property.valueType}`"
              />
            </div>
            <span
              v-else
            >
              <BFormCheckbox
                v-if="property.valueType === propertyValueTypes.boolean"
                v-model="property.value"
                disabled
                switch
                size="md"
              />
              <div v-else>
                {{ property.value || '-' }}
              </div>
            </span>
          </div>
          <div
            v-if="editForm && property.valueType === propertyValueTypes.dictionary
              && property.value !== null && property.value?.length > 0"
            class="value-icon"
            style="cursor: pointer; font-size: 14px;"
            @click="propertyClearClicked(property.id)"
          >
            <div
              v-tippy="{
                arrow: true,
                placement: 'top',
              }"
              :content="$t('general.clear')"
            >
              <i class="fa-regular fa-circle-xmark" />
            </div>
          </div>
          <div
            v-if="property.type.toUpperCase() === 'IMPORT'"
            class="value-icon"
            :style="editForm ? 'cursor: pointer' : 'cursor: default'"
            @click="propertySyncClicked(property.id)"
          >
            <i
              v-if="!property.editable"
              class="fa-solid fa-link"
            />
            <i
              v-else
              style="color: rgba(200, 20, 20, 0.9)"
              class="fa-solid fa-link-slash"
            />
          </div>
          <div
            v-else
            class="value-icon"
          >
            <i class="fa-solid fa-user-pen" />
          </div>
        </div>
      </div>
      <div
        v-if="!skuProperties.length"
        class="no-properties-info"
      >
        {{ $t('general.none') }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { propertyValueTypes } from '@/utils/dictionary';

export default {
  props: {
    skuProperties: Array,
    editForm: Boolean,
    header: String,
    unfoldedByDefault: Boolean,
  },
  data: () => ({
    propertyValueTypes,
    unfolded: true,
  }),
  computed: {
    ...mapState({
      dictionaries: state => state.dictionaries.list || [],
    }),
  },
  methods: {
    getDictionaryEntriesForBox(dictionaryId) {
      const dictionaryEntries = this.dictionaries.find(dict => dict.id === dictionaryId)?.entries || [];
      return dictionaryEntries.map(x => ({
        value: x.key,
        text: x.key,
      }));
    },
    propertyUpdated(propertyId) {
      const property = this.skuProperties
        .find(prop => prop.id === propertyId);
      property.isValid = this.isValueValid(property.valueType, property.value);
      this.$emit('update:property', property);
    },
    isValueValid(valueType, value) {
      if (value === null || value === '') {
        return true;
      }

      if (valueType === propertyValueTypes.integer) {
        return /^-?(0|[1-9]\d*)$/.test(value);
      }

      if (valueType === propertyValueTypes.decimal) {
        return /^-?(0|[1-9]\d*)((\.|,)\d+)?$/.test(value);
      }

      return true;
    },
    propertySyncClicked(propertyId) {
      if (!this.editForm) {
        return;
      }
      const property = this.skuProperties
        .find(prop => prop.id === propertyId);
      property.editable = !property.editable;
      this.propertyUpdated(propertyId);
    },
    propertyClearClicked(propertyId) {
      if (!this.editForm) {
        return;
      }
      const property = this.skuProperties
        .find(prop => prop.id === propertyId);
      property.value = null;
      this.propertyUpdated(propertyId);
    },
  },
  created() {
    this.unfolded = this.unfoldedByDefault;
  },
};
</script>

  <style lang="scss" scoped>

    .label-value-row {
      display: flex;
      align-items: center;
      margin-bottom: 1.5rem;

      .label {
        width: 35%;
        font-size: 12px;
        font-weight: 450;
        color: #979797;

        .label-description {
          font-size: 10px;
          font-weight: 400;
          font-style: italic;
          word-break: break-all;
        }
      }

      .value {
        display: flex;
        align-items: center;
        width: 60%;

        .value-value {
          padding-left: 10px;
          width: 90%;
        }

        .value-icon {
          padding-left: 10px;
          font-size: 12px;
          width: 10%;
          color: #979797;
        }
      }
    }

    .properties-type-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 1.5rem;
        font-size: 16px;
        font-weight: 500;
        cursor: pointer;
        padding-right: 10%;

        .arrow {
            color: #979797;
            font-size: 20px;
        }
    }

    .no-properties-info {
        text-align: center;
        font-size: 12px;
        font-weight: 350;
        font-style: italic;
        color: #979797;
    }
  </style>
