<script>
import OrderGeneralTab from '@/components/order/tabs/OrderGeneralTab.vue';

export default {
  name: 'CockpitActiveOrderDetailsTab',
  props: {
    activeOrder: Object,
  },
  emits: ['refresh'],
  components: {
    OrderGeneralTab,
  },
};
</script>

<template>
  <div class="position-relative order-data">
    <div
      class="refresh-button"
      @click="$emit('refresh')"
    >
      <i class="fas fa-refresh" />
      {{ $t('cockpit.refresh') }}
    </div>

    <OrderGeneralTab
      v-if="true"
      :selected="activeOrder.order"
    />
  </div>
</template>

<style scoped lang="scss">
.refresh-button {
  position: absolute;
  top: 0;
  right: 0;
  padding: 2px 26px;
  color: #7F7F7F;
  background-color: #EFEFEF;
  cursor: pointer;
  font-size: 12px;
  border-radius: 50px;
  font-weight: 500;

  &:hover {
    background-color: #D9D9D9;
  }
}

.info-row {
  display: flex;
  width: 100%;
  font-size: 12px;
  margin-bottom: 28px;
  font-weight: 500;

  &:last-child {
    margin-bottom: 0;
  }

  .label {
    width: 30%;
    color: #979797;
  }

  .value {
    width: 70%;
    color: #3F3F3F;

    &.order-progress {
      width: 50%;
    }

    .status-badge {
      display: inline-flex;
      align-items: center;
      gap: 4px;
      padding: 2px 16px;
      border-radius: 4px;
      background-color: #5886C1;
      font-size: 10px;
      color: white;

      i {
        font-size: 12px;
      }
    }
  }
}

.line {
  width: 100%;
  height: 1px;
  background-color: #ECECEC;
  margin-bottom: 28px;
}

.order-data {
  &:deep() {
    .label-value-row {
      margin-bottom: 28px;

      .label {
        font-size: 12px;
        font-weight: 500;
      }

      .value {
        font-size: 12px;
        font-weight: 500;
      }
    }
  }
}
</style>
