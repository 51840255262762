<script>
import CockpitMatryoshkaInheritanceTreeSectionItem
from '@/components/cockpit/matryoshka/inheritance-tree/CockpitMatryoshkaInheritanceTreeSectionItem.vue';

export default {
  name: 'CockpitMatryoshkaInheritanceTreeSection',
  props: {
    upperLevel: Boolean,
    lowerLevel: Boolean,
    showConnectButton: Boolean,
    label: String,
    selectedIds: Array,
    items: Array,
  },
  emits: [
    'connect',
    'disconnect',
    'disconnect-selected',
    'select-item-id',
    'go-to-serial-number',
  ],
  components: {
    CockpitMatryoshkaInheritanceTreeSectionItem,
  },
  methods: {
    isIdSelected(id) {
      return (this.selectedIds || []).includes(id);
    },
  },
};
</script>

<template>
  <div class="inheritance-tree">
    <div class="label">
      {{ label }}

      <BButton
        v-if="((upperLevel && !items.length) || lowerLevel)
          && showConnectButton
          && selectedIds
          && selectedIds.length === 0"
        variant="primary"
        class="connect-button"
        @click="$emit('connect')"
      >
        <i class="fas fa-link" />

        {{ $t('cockpit.connectElement') }}
      </BButton>
      <BButton
        v-if="selectedIds.length > 0"
        variant="danger"
        class="connect-button"
        @click="$emit('disconnect-selected')"
      >
        <i class="fas fa-unlink" />

        {{ $t('cockpit.disconnectSelectedElements') }} ({{ selectedIds.length }})
      </BButton>
    </div>

    <div class="children">
      <CockpitMatryoshkaInheritanceTreeSectionItem
        v-for="item in items"
        :key="item.id"
        :item="item"
        :level-icon="upperLevel ? 'fas fa-arrow-up-short-wide' : 'fas fa-arrow-down-short-wide'"
        :can-select="lowerLevel"
        :is-selected="isIdSelected(item.id)"
        @go-to-serial-number="$emit('go-to-serial-number', $event)"
        @select-item-id="$emit('select-item-id', $event)"
        @disconnect="$emit('disconnect', item.id)"
      />

      <div
        v-if="items.length === 0"
        class="no-connections-box"
      >
        <div class="box-label">
          {{ $t('cockpit.noConnectedElements') }}
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.inheritance-tree {
  margin-bottom: 16px;

  .label {
    font-size: 12px;
    font-weight: 500;
    color: black;
    margin-left: 30px;
    margin-bottom: 4px;

    .connect-button {
      margin-left: 10px;
      padding: 2px 10px;
      font-size: 11px;
      font-weight: 500;

      i {
        margin-right: 4px;
      }
    }
  }

  .children {
    display: flex;
    flex-direction: column;
    gap: 5px;

    .no-connections-box {
      padding: 10px 30px 40px 30px;

      .box-label {
        font-size: 14px;
        color: #979797;
      }
    }
  }
}
</style>
