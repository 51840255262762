<script>
import MarkingsAssignListItem from '@/components/markings/assign-list/MarkingsAssignListItem.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'MarkingsAssignList',
  emits: [
    'select-item',
  ],
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    disabledIds: {
      type: Array,
      default: () => [],
    },
    lowerLevel: Boolean,
  },
  components: {
    MarkingsAssignListItem,
  },
  computed: {
    ...mapGetters('packingSettings', [
      'getHigherLevelUnit',
      'getLowerLevelUnit',
    ]),
  },
  methods: {
    hasElementHigherUnitParents(item) {
      return (item.containers || [])
        .some(({ containerUnitId }) =>
          containerUnitId === this.getHigherLevelUnit(item.unitId, item.skuId)?.id);
    },
    isElementDisabled(item) {
      return this.disabledIds.includes(item.id)
        || (this.lowerLevel && this.hasElementHigherUnitParents(item));
    },
  },
};
</script>

<template>
  <div class="markings-list">
    <MarkingsAssignListItem
      v-for="item in list"
      :key="item.id"
      :item="item"
      :disabled="isElementDisabled(item)"
      @select-item="$emit('select-item', $event)"
    />
  </div>
</template>

<style scoped lang="scss">
.markings-list {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
</style>
