<template>
  <div
    class="d-flex order-execution col-12 row"
    :class="{ selected: isSelected(order.id) }"
  >
    <div class="col-6 pl-0">
      <div class="flex-grow-1">
        <div class="title-style no-wrap text-truncate">
          {{ order.description || '-' }}
        </div>
      </div>
      <div
        class="d-flex"
        style="align-text: left"
      >
        <div
          class="col-6 flex-grow-1 prop-style pt-1 pl-0"
        >
          <div class="pt-1">
            <span class="text-style">
              {{ $t('order.orderId') }}:
            </span>
            <span>
              {{ order.orderNumber || order.id || '-' }}
            </span>
          </div>
          <div class="pt-1">
            <span class="text-style">
              {{ $t('sku.sku') }}:
            </span>
            <span>
              {{ order.sku?.externalId || order.sku?.name || order.skuId || '-' }}
            </span>
          </div>
        </div>
        <div
          class="col-6 flex-grow-1 prop-style pt-1"
        >
          <div class="pt-1">
            <span class="text-style">
              {{ firstDateToDisplay.type }}:
            </span>
            <span>
              {{ formatDate(firstDateToDisplay.value) }}
            </span>
          </div>
          <div class="pt-1">
            <span class="text-style">
              {{ secondDateToDisplay.type }}:
            </span>
            <span>
              {{ formatDate(secondDateToDisplay.value) }}
            </span>
          </div>
        </div>
      </div>
    </div>

    <div
      class="col-2 py-2 px-0"
      style="align-self: center; text-align: left"
    >
      <div
        :style="{ 'background-color': status?.color || 'green' }"
        class="badge badge-danger text-white"
      >
        <div
          class="status-text-style px-1"
        >
          <i
            :class="status?.icon || ''"
            class="pr-1"
          />
          {{ status?.name || '-' }}
        </div>
      </div>
    </div>

    <div
      class="col-3 justify-content-center row"
      style="align-self: center"
    >
      <span
        class="badge badge-success text-white mr-2"
        style="border-radius: 10px"
        :style="{ 'background-color': badgeColor(orderType ? orderType.colorCode : null) }"
      >
        {{ orderType ? orderType.displayName : $t('order.order') }}
      </span>
    </div>

    <div
      class="col-1"
      style="align-self: center"
    >
      <div class="px-2 text-uppercase text-style">
        <span
          v-tippy="{ placement: 'top-end' }"
          :content="$t('line.line')"
          style="font-size: 0.8em"
        >
          {{ order.lineName }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';

export default {
  props: {
    order: Object,
    selected: Object,
  },
  computed: {
    ...mapGetters(['orderStatuses', 'orderBadge']),
    status() {
      return this.orderStatuses?.find(x => x.status.toUpperCase() === this.order.status.toUpperCase());
    },
    orderType() {
      return this.$store.getters.OrderTypeByName(this.order.type);
    },
    firstDateToDisplay() {
      let type = this.$t('general.created');
      let value = this.order.created;

      if (this.order.plannedStartDate) {
        type = this.$t('date.plannedStart');
        value = this.order.plannedStartDate;
      }

      if (this.order.startDate) {
        type = this.$t('date.start');
        value = this.order.startDate;
      }

      return { type, value };
    },
    secondDateToDisplay() {
      let type = this.$t('general.modified');
      let value = this.order.modified;

      if (this.order.plannedEndDate) {
        type = this.$t('date.plannedEnd');
        value = this.order.plannedEndDate;
      }

      if (this.order.endDate) {
        type = this.$t('date.end');
        value = this.order.endDate;
      }

      return { type, value };
    },
  },
  methods: {
    badgeColor(badgeColor) {
      return badgeColor ?? this.orderBadge.find(o => o.status === 'ORDER')?.color;
    },
    formatDate(date) {
      if (!date) return '-';
      return moment(date).format('ll LTS');
    },
    isSelected(orderId) {
      return this.selected?.id === orderId;
    },
  },
};
</script>

<style lang="scss" scoped>
  .order-execution {
    border-radius: 6px;
    border: 1px solid #F0F1F3;
    background: #FFF;
    box-shadow: 0px 1px 4px 0px #EEEFF0, 0px 1px 2px 0px rgba(227, 228, 228, 0.25);
    align-items: center;
    padding: 18px 25px;
    margin-block: 5px;
    line-height: 0.8;

    &:hover {
      background-color: rgba(200, 200, 200, 0.1);
      cursor: pointer;
    }

    &.selected {
      background-color: #EBEBEB;
    }
  }

  .badge {
    padding: 3px 8px 2px 11px;
    justify-content: center;
    align-items: flex-start;
    border-radius: 3.6px;
  }

  .btn-light,
  .btn-light:active,
  .btn-light:visited,
  .btn-light:focus {
      background-color: #EBEBEB;
      border-color: #EBEBEB;
  }

  .text-style {
    font-weight: 600;
    line-height: 1.2;
  }

  .prop-style {
    color: #7E7E7E;
    align-self: center;
    font-size: 11px;
  }

  .title-style {
    font-size: 13px;
    font-weight: 600;
  }

</style>
