<script>
import { mapActions, mapGetters } from 'vuex';
import SkuPackingStructureTree from '@/components/sku/packingStructure/SkuPackingStructureTree.vue';

export default {
  name: 'SkuPackingStructureTab',
  props: {
    sku: Object,
  },
  data() {
    return {
      showUnitsOnly: false,
      pendingSkuPackingStructure: false,
    };
  },
  filters: {
    capitalize(value) {
      if (!value) return '';
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
  },
  components: { SkuPackingStructureTree },
  computed: {
    ...mapGetters([
      'globalSettings',
    ]),
    ...mapGetters('packingSettings', [
      'getUnitsForSku',
      'getUnitById',
      'getHigherLevelUnit',
      'getLowerLevelUnit',
    ]),
    skuUnits() {
      return this.getUnitsForSku(this.sku.id);
    },
    rootUnit() {
      return this.skuUnits.find(unit => !this.getHigherLevelUnit(unit.id, this.sku.id));
    },
  },
  methods: {
    ...mapActions('packingSettings', [
      'getUnits',
      'getPackingSettings',
    ]),
    async fetchSkuPackingStructure() {
      const { plantCode } = this.globalSettings;
      this.pendingSkuPackingStructure = true;
      await this.getUnits({
        params: {
          plantCode,
        },
      });
      await this.getPackingSettings({
        params: {
          plantCode,
        },
      });
      this.pendingSkuPackingStructure = false;
    },
  },
  mounted() {
    this.fetchSkuPackingStructure();
  },
};
</script>

<template>
  <div>
    <Loader v-if="pendingSkuPackingStructure" />

    <div v-else>
      <BFormCheckbox v-model="showUnitsOnly">
        <div class="mt-1 font-weight-normal">
          {{ $t('showUnitsAsList') }}
          <i class="fas fa-list ml-1" />
        </div>
      </BFormCheckbox>

      <hr>

      <div
        v-if="showUnitsOnly"
        class="units-list"
      >
        <div
          v-for="unit in skuUnits"
          :key="unit.id"
          class="unit"
        >
          {{ unit.name | capitalize }}
        </div>
        <p
          v-if="skuUnits.length === 0"
          class="text-black-50 text-center mt-5"
        >
          <i class="fas fa-info-circle me-1" />

          {{ $t('noUnitsFound') }}
        </p>
      </div>

      <div v-else>
        <SkuPackingStructureTree
          v-if="rootUnit"
          :unit-id="rootUnit.id"
          :sku-id="sku.id"
        />
        <p
          v-else
          class="text-black-50 text-center mt-5"
        >
          <i class="fas fa-info-circle me-1" />

          {{ $t('noPackingStructureFound') }}
        </p>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.units-list {
  .unit {
    padding: 10px;
    display: inline-block;
    border-radius: 5px;
    background-color: #f0f0f0;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    border-left: 3px solid rgb(118, 176, 51);
    transition: all 0.3s;
    width: calc(100% - 20px);
    overflow: auto;
    margin-bottom: 15px;
  }
}
</style>
