var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sku-details-offcanvas"},[_c('div',{staticClass:"d-flex justify-content-between align-items-center mb-2",style:(_vm.editForm ? 'padding-bottom: 40px' : '')},[_c('h3',{staticClass:"align-self-center mb-0",staticStyle:{"font-size":"1.7em"}},[_c('i',{staticClass:"pr-2",class:_vm.editForm ? 'fas fa-pen' : 'fas fa-info-circle'}),_c('span',[_vm._v(" "+_vm._s(_vm.editForm ? _vm.$t('sku.editSku') : _vm.$t('sku.skuDetails'))+" ")])]),_c('div',{staticClass:"h3 mb-0 text-black-50"},[_c('i',{staticClass:"fas fa-times cursor-pointer p-1",on:{"click":function($event){_vm.$emit('close'); _vm.tab = 0}}})])]),_c('BTabs',{attrs:{"pills":"","content-class":"pt-2"},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('BTab',{key:"general",attrs:{"title":_vm.$t('general.general'),"title-item-class":_vm.editForm ? 'd-none' : ''}},[(_vm.pendingSku
          || _vm.pendingUpdateSku
          || _vm.pendingSkuPropertyTypeLabelUsages)?_c('Loader'):_c('GeneralSkuTab',{attrs:{"edit-form":_vm.editForm,"sku":_vm.sku},on:{"update:editForm":function($event){_vm.editForm = $event},"update:description":function($event){_vm.sku.description = $event},"update:property":function($event){return _vm.propertyUpdated($event)}}})],1),_c('BTab',{key:"restrictions",attrs:{"title":_vm.$t('labelTemplates'),"title-item-class":_vm.editForm ? 'd-none' : ''}},[(_vm.pendingSku
          || _vm.pendingUpdateSku
          || _vm.pendingSkuPropertyTypeLabelUsages)?_c('Loader'):_c('SkuLabelTemplatesTab',{attrs:{"sku":_vm.sku}})],1),_c('BTab',{key:"packingStructure",attrs:{"title":_vm.$t('packingStructure'),"title-item-class":_vm.editForm ? 'd-none' : ''}},[(_vm.pendingSku
          || _vm.pendingUpdateSku
          || _vm.pendingSkuPropertyTypeLabelUsages)?_c('Loader'):_c('SkuPackingStructureTab',{attrs:{"sku":_vm.sku}})],1)],1),(_vm.tab === 0)?_c('div',{staticClass:"offcanvas-footer d-flex align-items-center justify-content-center mt-2"},[(_vm.editForm)?_c('div',[_c('BButton',{staticClass:"px-5 mr-3",attrs:{"pill":"","size":"sm"},on:{"click":function($event){return _vm.cancel()}}},[_c('i',{staticClass:"ion ion-md-close"}),_vm._v(" "+_vm._s(_vm.$t('general.cancel'))+" ")]),_c('BButton',{staticClass:"px-5 mr-3",attrs:{"pill":"","size":"sm","variant":"primary","disabled":!_vm.dataValid},on:{"click":function($event){return _vm.save()}}},[_c('i',{staticClass:"fas fa-check pr-2"}),_vm._v(" "+_vm._s(_vm.$t('general.save'))+" ")])],1):_vm._e()]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }