<script>
import OrderProgressBar from '@/components/cockpit/activeOrder/OrderProgressBar.vue';
import { mapState } from 'vuex';

export default {
  name: 'CockpitActiveOrderCard',
  props: {
    activeOrders: Array,
  },
  components: {
    OrderProgressBar,
  },
  computed: {
    ...mapState({
      lines: state => state.lines.list || [],
      labelingPoints: state => state.labelingPoints.list || [],
    }),
    orderLineName() {
      return this.lines.find(l => l.id === this.orderDetails?.lineId)?.name || '';
    },
    orderLabelingPoints() {
      const labelingPoints = this.activeOrders.map(order => order.labelingPointId);
      return this.labelingPoints
        .filter(lp => labelingPoints.includes(lp.id))
        .map(lp => lp.name);
    },
    orderDetails() {
      return this.activeOrders[0]?.order || {};
    },
  },
  emits: [
    'select-order',
  ],
};
</script>

<template>
  <div class="cockpit-active-order-card">
    <div class="active-order-heading">
      <i class="fas fa-play-circle" />
      {{ $t('cockpit.activeOrder') }}
    </div>

    <div
      v-if="false"
      class="line"
    />

    <div
      class="order-box"
      @click.stop="$emit('select-order', 1)"
    >
      <div class="order-info">
        <div class="order-number">
          {{ $t('order.orderNo') }} {{ orderDetails.orderNumber }}
        </div>

        <div class="order-info-columns">
          <div>
            <div>
              <span class="info-label">
                {{ $t('order.orderId') }}:
              </span>
              <span>
                {{ orderDetails.orderNumber }}
              </span>
            </div>
            <div>
              <span class="info-label">
                {{ $t('order.sku') }}:
              </span>
              <span>
                {{ orderDetails.sku.name || orderDetails.sku.description }}
              </span>
            </div>
          </div>
          <div>
            <div>
              <span class="info-label">
                {{ $t('order.bbd') }}:
              </span>
              <span>
                ?
              </span>
            </div>
            <div>
              <span class="info-label">
                {{ $t('order.batchId') }}:
              </span>
              <span>
                ?
              </span>
            </div>
          </div>
          <div>
            <div>
              <span class="info-label">
                {{ $t('order.ean') }}:
              </span>
              <span>
                ?
              </span>
            </div>
          </div>
        </div>
      </div>

      <div class="order-progress">
        <OrderProgressBar
          v-if="orderDetails.orderedQuantity"
          :value="orderDetails.done"
          :total="orderDetails.orderedQuantity"
          :unit="orderDetails.orderedUnit"
        />
      </div>

      <div class="order-line">
        <div class="line-name">
          {{ orderLineName }}
        </div>
        /
        <div class="subline-name">
          <div class="labeling-points">
            <div
              v-for="orderLabelingPoint in orderLabelingPoints"
              :key="orderLabelingPoint"
            >
              {{ orderLabelingPoint }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.cockpit-active-order-card {
  background-color: #FFFFFF;
  width: 100%;
  border-radius: 9px;
  padding: 16px;

  .active-order-heading {
    font-size: 18px;
    font-weight: 500;
    //padding-bottom: 16px;

    i {
      margin-right: 8px;
    }
  }

  .line {
    border-bottom: 1px solid #F0F1F3;
  }

  .order-progress {
    width: 24%;
    margin-top: 16px;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .order-box {
    margin-top: 16px;
    padding: 16px 30px 16px 16px;
    border-radius: 6px;
    border: 1px solid #F0F1F3;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    box-shadow: 0 1px 4px 0 #EEEFF0;

    &:hover {
      background-color: #f8f8f8;
    }

    .order-info {
      .order-number {
        font-size: 13px;
        font-weight: 500;
        margin-bottom: 6px;
      }

      .order-info-columns {
        gap: 2rem;
        font-size: 11px;
        color: #7E7E7E;
        display: flex;

        .info-label {
          font-weight: 500;
        }
      }
    }
  }

  .order-line {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2px;
    font-size: 11px;
    font-weight: 400;
    color: #7E7E7E;

    .line-name {
      font-size: 13px;
      font-weight: 500;
      margin-bottom: 4px;
      margin-right: 5px;
      color: black;
    }

    .labeling-points {
      display: flex;
      gap: 2px;
      flex-direction: column;
      font-size: 10px;
      margin-left: 5px;
    }
  }
}
</style>
