<script>
import MarkingStatusBadge from '@/components/markings/MarkingStatusBadge.vue';
import moment from 'moment';

export default {
  name: 'CockpitMatryoshkaItemHistoryListItem',
  props: {
    status: String,
    timestamp: String,
  },
  components: { MarkingStatusBadge },
  filters: {
    parseDate(value) {
      return moment(value).format('DD MMM YYYY, HH:mm:ss');
    },
  },
};
</script>

<template>
  <div class="history-list-item">
    <div class="status">
      <MarkingStatusBadge
        :status="status"
      />
    </div>

    <div class="date">
      {{ timestamp | parseDate }}
    </div>
  </div>
</template>

<style scoped lang="scss">
.history-list-item {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 13px;

  .date {
    color: #686868;
  }
}
</style>
