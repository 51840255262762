<script>
import { mapGetters } from 'vuex';

export default {
  name: 'SkuPackingStructureTree',
  props: {
    unitId: Number,
    skuId: Number,
    amount: Number,
  },
  filters: {
    capitalize(value) {
      if (!value) return '';
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
  },
  computed: {
    ...mapGetters('packingSettings', [
      'getUnitsForSku',
      'getUnitById',
      'getHigherLevelUnit',
      'getLowerLevelUnit',
    ]),
    unitObject() {
      return this.getUnitById(this.unitId) || {};
    },
    lowerUnit() {
      return this.getLowerLevelUnit(this.unitId, this.skuId);
    },
  },
};
</script>

<template>
  <div class="unit-box">
    <div class="d-flex align-items-center">
      {{ unitObject.name | capitalize }}

      <p
        v-if="amount"
        class="badge badge-primary mb-0 ml-2"
        style="font-size: 0.8rem"
      >
        x{{ amount || "" }}
      </p>
    </div>

    <div v-if="lowerUnit">
      <SkuPackingStructureTree
        v-if="lowerUnit"
        :unit-id="lowerUnit.id"
        :sku-id="skuId"
        :amount="lowerUnit.connectionAmount"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
.unit-box {
  padding: 10px;
  display: inline-block;
  border-radius: 5px;
  background-color: #f0f0f0;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  border-left: 3px solid rgb(118, 176, 51);
  transition: all 0.3s;
  width: calc(100% - 20px);
  overflow: auto;

  .lower-unit {
    margin-left: 20px;
    margin-top: 20px;
  }

  .unit-box {
    margin-left: 20px;
    margin-top: 20px;
  }
}
</style>
