<template>
  <div>
    <div
      class="properties-type-header"
      @click="unfolded=!unfolded"
    >
      <div>
        {{ header }}
      </div>
      <div class="arrow">
        <i
          v-if="!unfolded"
          class="fa-solid fa-angle-down"
        />
        <i
          v-else
          class="fa-solid fa-angle-up"
        />
      </div>
    </div>

    <div v-if="unfolded">
      <div
        v-for="property in orderProperties"
        :key="property.name"
        class="label-value-row"
      >
        <div class="label">
          {{ property.name || property.description }}
        </div>
        <div class="value">
          {{ property.value || '-' }}
        </div>
      </div>
      <div
        v-if="!orderProperties.length"
        class="no-properties-info"
      >
        {{ $t('general.none') }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { propertyValueTypes } from '@/utils/dictionary';

export default {
  props: {
    orderProperties: Array,
    editForm: Boolean,
    header: String,
    unfoldedByDefault: Boolean,
  },
  data: () => ({
    propertyValueTypes,
    unfolded: true,
  }),
  computed: {
    ...mapState({
      dictionaries: state => state.dictionaries.list || [],
    }),
  },
  methods: {
    getDictionaryEntriesForBox(dictionaryId) {
      const dictionaryEntries = this.dictionaries.find(dict => dict.id === dictionaryId)?.entries || [];
      return dictionaryEntries.map(x => ({
        id: x.key,
        name: x.key,
        type: x.value,
      }));
    },
    propertyUpdated(propertyId) {
      const property = this.orderProperties
        .find(prop => prop.id === propertyId);
      property.isValid = this.isValueValid(property.valueType, property.value);
      this.$emit('update:property', property);
    },
    isValueValid(valueType, value) {
      if (value === null || value === '') {
        return true;
      }

      if (valueType === propertyValueTypes.integer) {
        return /^(0|-*[1-9]+[0-9]*)$/.test(value);
      }

      if (valueType === propertyValueTypes.decimal) {
        return /^-?(0|[1-9]\d*)(\.\d+)?$/.test(value);
      }

      return true;
    },
    propertySyncClicked(propertyId) {
      if (!this.editForm) {
        return;
      }
      const property = this.orderProperties
        .find(prop => prop.id === propertyId);
      property.editable = !property.editable;
      this.propertyUpdated(propertyId);
    },
  },
  created() {
    this.unfolded = this.unfoldedByDefault;
  },
};
</script>

    <style lang="scss" scoped>

      .label-value-row {
        display: flex;
        align-items: center;
        margin-bottom: 1.5rem;

        .label {
          width: 35%;
          font-size: 12px;
          font-weight: 450;
          color: #979797;

          .label-description {
            font-size: 10px;
            font-weight: 400;
            font-style: italic;
            word-break: break-all;
          }
        }

        .value {
          display: flex;
          align-items: center;
          width: 60%;

          .value-value {
            padding-left: 10px;
            width: 90%;
          }

          .value-icon {
            padding-left: 10px;
            font-size: 12px;
            width: 10%;
            color: #979797;
          }
        }
      }

      .properties-type-header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 1.5rem;
          font-size: 16px;
          font-weight: 500;
          cursor: pointer;

          .arrow {
              color: #979797;
              font-size: 20px;
          }
      }

      .no-properties-info {
          text-align: center;
          font-size: 12px;
          font-weight: 350;
          font-style: italic;
          color: #979797;
      }
    </style>
