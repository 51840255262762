<script>

import CockpitStatusBadge from '@/components/cockpit/CockpitStatusBadge.vue';
import { mapActions, mapGetters, mapState } from 'vuex';
import MarkingStatusBadge from '@/components/markings/MarkingStatusBadge.vue';

export default {
  name: 'CockpitMatryoshkaItemDetailsTab',
  props: {
    labelArchiveId: Number,
  },
  data: () => ({
    loadingDetails: false,
    itemDetails: {},
    skuDetails: {},
  }),
  components: {
    MarkingStatusBadge,
    CockpitStatusBadge,
  },
  computed: {
    ...mapGetters([
      'globalSettings',
    ]),
    ...mapState({
      lines: state => state.lines.list || [],
      labelingPoints: state => state.labelingPoints.list || [],
    }),
    lineName() {
      return this.lines.find(l => l.id === this.itemDetails.lineId)?.name || '';
    },
    labelingPointName() {
      return this.labelingPoints.find(l => l.id === this.itemDetails.labelingPointId)?.name || '';
    },
  },
  watch: {
    labelArchiveId: {
      handler() {
        this.getDetails();
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions('labelArchive', [
      'getLabelArchiveItem',
      'updateLabelArchiveItem',
    ]),
    ...mapActions([
      'getSku',
    ]),
    async fetchSku() {
      if (!this.labelArchiveId) return;

      const { data } = await this.getSku({
        params: {
          skuId: this.itemDetails.skuId,
        },
      });
      this.skuDetails = data;
    },
    async fetchLabelArchiveItem() {
      if (!this.labelArchiveId) return;

      const { plantCode } = this.globalSettings;
      const { data } = await this.getLabelArchiveItem({
        params: {
          id: this.labelArchiveId,
          plantCode,
        },
      });
      this.itemDetails = { ...data };
    },
    async getDetails() {
      this.loadingDetails = true;

      await this.fetchLabelArchiveItem();
      await this.fetchSku();

      this.loadingDetails = false;
    },
  },
};
</script>

<template>
  <div>
    <Loader
      v-if="loadingDetails"
    />

    <div
      v-else
      class="position-relative"
    >
      <div class="info-row">
        <div class="label">
          {{ $t('cockpit.serialNumber') }}
        </div>
        <div class="value">
          <span>
            {{ itemDetails.serialNumber }}
          </span>
        </div>
      </div>

      <div class="info-row">
        <div class="label">
          {{ $t('order.orderId') }}
        </div>
        <div class="value">
          <span>
            {{ itemDetails.orderId }}
          </span>
        </div>
      </div>

      <div class="info-row">
        <div class="label">
          {{ $t('order.batchId') }}
        </div>
        <div class="value">
          <span>
            {{ itemDetails.batchId }}
          </span>
        </div>
      </div>

      <div class="info-row">
        <div class="label">
          {{ $t('order.sku') }}
        </div>
        <div class="value">
          <span>
            {{ itemDetails.skuId }}
          </span>
        </div>
      </div>

      <div class="info-row">
        <div class="label">
          {{ $t('order.skuDescription') }}
        </div>
        <div class="value">
          {{ skuDetails.name }}
        </div>
      </div>

      <div class="info-row">
        <div class="label">
          {{ $t('order.line') }}
        </div>
        <div class="value">
          <span>
            {{ lineName }}
          </span>
        </div>
      </div>

      <div class="info-row">
        <div class="label">
          {{ $t('order.labelingPoint') }}
        </div>
        <div class="value">
          <span>
            {{ labelingPointName }}
          </span>
        </div>
      </div>

      <div class="info-row">
        <div class="label">
          {{ $t('order.restriction') }}
        </div>
        <div class="value">
          ?
        </div>
      </div>

      <div class="info-row">
        <div class="label">
          {{ $t('order.status.status') }}
        </div>
        <div class="value">
          <MarkingStatusBadge
            :status="itemDetails.labelStatus"
          />
        </div>
      </div>

      <div class="line" />

      <div>
        <div class="info-row">
          <div class="label">
            {{ $t('cockpit.printDate') }}
          </div>
          <div class="value">
            ?
          </div>
        </div>

        <div class="info-row">
          <div class="label">
            {{ $t('cockpit.printType') }}
          </div>
          <div class="value">
            ?
          </div>
        </div>

        <div class="info-row">
          <div class="label">
            {{ $t('cockpit.printStatus') }}
          </div>
          <div class="value">
            ?
          </div>
        </div>

        <div class="info-row">
          <div class="label">
            {{ $t('cockpit.printCopyCount') }}
          </div>
          <div class="value">
            ?
          </div>
        </div>

        <div class="info-row">
          <div class="label">
            {{ $t('cockpit.printCancelDate') }}
          </div>
          <div class="value">
            ?
          </div>
        </div>

        <div class="info-row">
          <div class="label">
            {{ $t('cockpit.printStatusStamp') }}
          </div>
          <div class="value">
            ?
          </div>
        </div>

        <div class="info-row">
          <div class="label">
            {{ $t('cockpit.type') }}
          </div>
          <div class="value">
            ?
            <CockpitStatusBadge
              v-if="false"
              label="TYPE"
              icon="fas fa-box"
              color="#F0F1F3"
              text-color="black"
            />
          </div>
        </div>

        <div class="info-row">
          <div class="label">
            {{ $t('cockpit.fullPallet') }}
          </div>
          <div class="value">
            ?
          </div>
        </div>

        <div class="info-row">
          <div class="label">
            {{ $t('cockpit.lastPallet') }}
          </div>
          <div class="value">
            ?
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.edit-button {
  position: absolute;
  top: 0;
  right: 0;
  padding: 2px 26px;
  color: #7F7F7F;
  background-color: #EFEFEF;
  cursor: pointer;
  font-size: 12px;
  border-radius: 50px;
  font-weight: 500;

  &:hover {
    background-color: #D9D9D9;
  }
}

.info-row {
  display: flex;
  width: 100%;
  font-size: 12px;
  margin-bottom: 28px;
  font-weight: 500;

  &:last-child {
    margin-bottom: 0;
  }

  .label {
    width: 30%;
    color: #979797;
  }

  .value {
    width: 70%;
    color: #3F3F3F;

    input, select {
      width: calc(100% - 7rem);
      font-size: 11px;
      font-weight: 500;
      height: 18px;
      border: 1px solid #ECECEC;
      border-radius: 4px;
      padding: 0 8px;

      &.custom-select {
        padding: 0 8px;
      }
    }

    &.order-progress {
      width: 50%;
    }
  }
}

.action-buttons-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  .action-button {
    padding: 4px 30px;
    font-size: 15px;

    i {
      margin-right: 4px;
    }
  }
}

.line {
  width: 100%;
  height: 1px;
  background-color: #ECECEC;
  margin-bottom: 28px;
}
</style>
