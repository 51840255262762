<script>
import CockpitMatryoshkaItemAuditListItem
from '@/components/cockpit/matryoshka/audit/CockpitMatryoshkaItemAuditListItem.vue';
import moment from 'moment';

export default {
  name: 'CockpitMatryoshkaItemAuditList',
  data: () => ({
    audit: [
      {
        user: 'Adam Nowak',
        action: 'Zmiana statusu na REM',
        date: moment(),
      },
      {
        user: 'Jan Nowak',
        action: 'przypiął kartonik 330821501423612',
        date: moment(),
      },
      {
        user: 'Jan Kowalski',
        action: 'odłączył kartonik 330821501423613',
        date: moment(),
      },
      {
        user: 'Jan Kowalski',
        action: 'odłączył kartonik 330821501423614',
        date: moment(),
      },
    ],
  }),
  components: { CockpitMatryoshkaItemAuditListItem },
};
</script>

<template>
  <div class="audit-list">
    <CockpitMatryoshkaItemAuditListItem
      v-for="item in audit"
      :key="item.status"
      :user="item.user"
      :action="item.action"
      :date="item.date"
      class="audit-list-item"
    />
  </div>
</template>

<style scoped lang="scss">

</style>
